.colorHeytech {
    color: $heytech;
}

.divider {
    width: 100%;
    border-top: solid 1px lightgray;
    margin: 16px 0px;
}

th,
td {
    vertical-align: middle;
}

.loginBisaroImage {
    padding: 16px;
    text-align: center;

    img {
        max-width: 480px;
    }
}

.badge-primary {
    background-color: #34c38f !important;
}

.badge-secondary {
    background-color: #003A5D !important;
}

.table-responsive {
    padding-bottom: 65px;

    .react-bootstrap-table {
        table {
            table-layout: auto;
        }
    }

    .react-bootstrap-table-pagination {
        position: absolute;
        width: 100%;
        bottom: 0px;
    }
}

.react-pdf__Document {
    text-align: center;
    max-width: 100%;

    .react-pdf__Page canvas {
        margin: 0 auto;
        max-width: 100%;
        height: auto !important;
    }
}

.table-nowrap th,
.table-nowrap td {
    white-space: break-spaces;
    max-width: 400px;
}

.btnChoiseType {
    text-align: center;
    width: 100%;
    height: 90px;
    padding-left: 102px;

    .logoBtn {
        width: 90px;
        height: 90px;
        padding: 10px;
        margin: 0 auto;
    }

    &.btn-primary {
        .logoBtn {
            filter: brightness(0) invert(1);
        }
    }

    &:focus {
        box-shadow: none !important;
    }
}

.floating-btn-bottom-right {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 9999;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    font-size: 24px;

    @media screen and (max-width: 600px) {
        width: 100%;
        right: 0px;
        bottom: 0px;
    }
}

$start: 0;
$end: 100;

@for $i from $start through $end {
    .start-#{$i} {
        left: #{$i}#{"%"} !important;
    }
}

@media screen and (min-width: 600px) {
    .modal-fullscreen {
        @for $i from $start through $end {
            &.partial_fullscreen-#{$i} {
                height: #{$i}#{"%"} !important;
                top: #{100-$i}#{"%"} !important;
            }
        }
    }
}

.search-label {
    margin-bottom: 0px;
}

.cliccable {
    cursor: pointer;
}

.short_list_icon_type {
    width: 30px;
    height: auto;
}

.form_materialediconsumo {
    label {
        white-space: nowrap;
    }
}

.ht_btn_bar_top {
    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width: 600px) {
    .table_wrapper_mobile {
        overflow-x: auto;
    }

    /* thead{
        tr{
            display: flex;
            flex-direction: column;
            width: 100%;
            box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
            border-bottom-width: 1px;
            th:not(.sortable){
                display: none;
            }
        }
    }
    tbody{
        tr{
            display: flex;
            flex-direction: column;
            width: 100%;
            //box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
            border-bottom-width: 1px;
            border-color: $primary;
            padding-bottom: 10px;
            margin-top: 10px;
            td{
                border: none!important;
                padding: 0px!important;
                line-height: 1.5rem!important;
                input{
                    margin: 0px!important;
                }
                .avatar-xs{
                    display: none;
                }
            }
        }
    } */
}

.ht_list_tab {
    padding-left: 0px;

    li {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: solid 1px lightgrey;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        p,
        label {
            margin: 0px;
        }

        * {
            cursor: pointer;
        }
    }
}

.ht_line_left {
    border-left: solid 1px lightgrey;

    @media screen and (max-width: 600px) {
        border-left: none;
    }
}

.ht_no_margin_p {
    p {
        margin: 0px;
    }
}

.ht_elipse_nav {
    li.nav-item {
        width: 100%;

        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}

.ht-important-card .card {
    background-color: $primary;
    color: white;

    * {
        color: white;
    }
}

.signPad {
    width: 100%;
    height: 100%;
    position: absolute;
}

.react-pdf__Page {
    background: lightgrey;
    padding: 16px;
}

.search-box {
    @media screen and (max-width: 767px) {
        width: 100%;

        .search-label {
            width: 100%;
        }
    }
}

.accordion-button.alwaysOpen::after {
    display: none;
}

.tagInput {
    .form-control .badge {
        align-items: center;
        display: flex;
    }
}

.tags-content {
    margin: 0px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ced4da;
    height: fit-content;
    gap: 3px;
    border-radius: 0.25rem;
    width: 100%;
}

.tag {
    padding: 5px;
    border: 1px solid #eff2f7;
    background: #eff2f7;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
}

.tag .remove-tag {
    margin: 3px;
    color: #aa182c;
    cursor: pointer;
}

.tags-content input {
    border: 0;
    padding: 10px;
}

.tags-input {
    position: relative;
}

.tags-suggestions {
    border: 1px solid;
    padding: 5px;
    position: absolute;
    top: 45px;
    bottom: 0px;
    height: fit-content;
    width: 100%;
    z-index: 99999;
    background: #eff2f7;
    border-color: #eff2f7;
    border-radius: 0.25rem;
}

.suggestion-item {
    cursor: pointer;
    margin: 10px 0;
    padding: 5px;
    background: #fff;
    border-radius: 0.25rem;
}

#pdf-renderer {
    min-height: calc(100vh - 100px);

    #pdf-download {
        display: none !important;
    }
}

#msdoc-iframe {
    min-height: calc(100vh - 100px) !important;
}